import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { http } from '../utils/http';
import { getMydata } from '../redux/action/useraction';

function AuthWrapper({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserAuthentication = async () => {
      const jwt = localStorage.getItem('userToken');
      const userId = localStorage.getItem('userId');

      if (jwt && userId) {
        try {
          const response = await getMydata()

        //   if (response.data) {
        //     navigate('/Home');
        //   }
        } catch (error) {
          console.error('Failed to fetch user data:', error);
          localStorage.removeItem('userToken');
          localStorage.removeItem('userId');
          localStorage.removeItem('user');
          toast.error('Session expired, please log in again.');
  
        }
      } else {
        navigate('/login');
      }
    };

    checkUserAuthentication();
  }, [navigate]);

  return (
    <div>
      <ToastContainer />
      {children}
    </div>
  );
}

export default AuthWrapper;
