import React, { useEffect } from 'react';
import { addUsersInReducer } from '../../redux/action/useraction';
import { addDoctorInReducer } from '../../redux/action/doctor';
import { useSelector, useDispatch } from 'react-redux';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import PeopleIcon from '@mui/icons-material/People';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { useNavigate } from 'react-router-dom';
import { addRecipessInReducer } from '../../redux/action/recipes';
import { addWorkoutsInReducer } from '../../redux/action/workouts';
import { addPlansInReducer, addTestsInReducer } from '../../redux/action/plans';

function Home() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const data = useSelector((state) => state.user);
    
    const getData = async () => {
        try {
            dispatch(addUsersInReducer());
            dispatch(addDoctorInReducer());
            dispatch(addRecipessInReducer());
            dispatch(addWorkoutsInReducer());
            dispatch(addTestsInReducer())
            dispatch(addPlansInReducer())
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };

  useEffect(() => {
    getData();
  }, []);

    return (
        <Box sx={{ padding: '20px' }}>

            <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} sm={6} md={3}>
                    <Card onClick={()=>navigate("users")}  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px', backgroundColor: '#f5f5f5' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <PeopleIcon sx={{ fontSize: 50, color: '#3f51b5', marginRight: '10px' }} />
                            <Typography variant="h6">Users</Typography>
                        </Box>
                        <Typography variant="h4">{data?.userArray?.length || 0}</Typography>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card onClick={()=>navigate("doctors")} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px', backgroundColor: '#f5f5f5' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <LocalHospitalIcon sx={{ fontSize: 50, color: '#f44336', marginRight: '10px' }} />
                            <Typography variant="h6">Doctors</Typography>
                        </Box>
                        <Typography variant="h4">{data?.doctors?.length || 0}</Typography>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px', backgroundColor: '#f5f5f5' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <RestaurantMenuIcon sx={{ fontSize: 50, color: '#ff9800', marginRight: '10px' }} />
                            <Typography variant="h6">Recipes</Typography>
                        </Box>
                        <Typography variant="h4">{data?.recipes?.length || 0}</Typography>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px', backgroundColor: '#f5f5f5' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FitnessCenterIcon sx={{ fontSize: 50, color: '#4caf50', marginRight: '10px' }} />
                            <Typography variant="h6">Workouts</Typography>
                        </Box>
                        <Typography variant="h4">{data?.workouts?.length || 0}</Typography>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px', backgroundColor: '#f5f5f5' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FitnessCenterIcon sx={{ fontSize: 50, color: '#4caf50', marginRight: '10px' }} />
                            <Typography variant="h6">Tests</Typography>
                        </Box>
                        <Typography variant="h4">{data?.tests?.length || 0}</Typography>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '16px', backgroundColor: '#f5f5f5' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <FitnessCenterIcon sx={{ fontSize: 50, color: '#4caf50', marginRight: '10px' }} />
                            <Typography variant="h6">Plans</Typography>
                        </Box>
                        <Typography variant="h4">{data?.plans?.length || 0}</Typography>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Home;
