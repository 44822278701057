// appReducer.js
const initialState = {
  userArray: [],
  me:null, // This will hold the array of users,
  doctors:[],
  templates:[],
  userData:[],
  recipes:[],
  workouts:[],
  test:[],
  plans:[]
};


const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_USER':
      return {
        ...state,
        userArray:action.payload, // Add new user to the array
      };
      case 'ADD_USER_DETAIL':
      return {
        ...state,
        userData:action.payload, // Add new user to the array
      };
      case 'GET_ALL_DOCTORS':
      return {
        ...state,
        doctors:action.payload, // Add new user to the array
      };
      case 'GET_ALL_TEMPLATES':
      return {
        ...state,
        templates:action.payload, // Add new user to the array
      };
      case 'GET_ALL_WORKOUTS':
      return {
        ...state,
        workouts:action.payload, // Add new user to the array
      };
      case 'GET_ALL_PLANS':
      return {
        ...state,
        plans:action.payload, // Add new user to the array
      };
      case 'GET_ALL_TESTS':
      return {
        ...state,
        tests:action.payload, // Add new user to the array
      };
      case 'GET_ALL_RECIPES':
      return {
        ...state,
        recipes:action.payload, // Add new user to the array
      };
      case 'ME_DATA':
      return {
        ...state,
        me:action.payload, // Add new user to the array
      };
    default:
      return state;
  }
};



export default userReducer;
