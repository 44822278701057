import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";
import { uploadimage, uploadimageMultiple } from "../../functions/upload";
import UserDropdown from "../../Components/UserDropdown";
import CloseIcon from "@mui/icons-material/Close";
import { http } from "../../utils/http";
import { useNavigate } from "react-router-dom";

const WorkoutForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    category: "",
    images: [],
    videos: [],
    assignedUsers: [],
    createdBy: "",
  });

  const user = useSelector((state) => state?.user?.userArray);
  const [users, setUsers] = useState(user);
  const [loading, setLoading] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const navigate = useNavigate();

  // Handle input change for text fields
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCategoryChange = (e) => {
    setFormData({
      ...formData,
      category: e.target.value,
    });
  };

  // Handle image uploads and preview
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);

    setFormData({
      ...formData,
      images: files,
    });

    // Preview the selected images
    const imagePreviews = files.map((file) => URL.createObjectURL(file));
    setPreviewImages(imagePreviews);
  };

  // Remove selected image
  const handleRemoveImage = (index) => {
    const updatedImages = [...formData.images];
    const updatedPreviews = [...previewImages];

    updatedImages.splice(index, 1);
    updatedPreviews.splice(index, 1);

    setFormData({
      ...formData,
      images: updatedImages,
    });

    setPreviewImages(updatedPreviews);
  };

  // Handle video uploads
  const handleVideoUpload = (e) => {
    setFormData({
      ...formData,
      videos: [...e.target.files],
    });
  };

  // Handle user assignment change (multi-select)
  const handleSelectChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      assignedUsers: e.target.value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    // data.append('name', formData.name);

    const body = {
      name: formData.name,
      description: formData.description,
      create_by: localStorage.getItem("userId"),
      users: formData.assignedUsers,
      category: formData.category,
    };

    // Append images
    // setLoading(false)
    const imageIds = await uploadimageMultiple(formData.images);
    console.log(imageIds);
    body.img = imageIds;

    // // Append videos
    // formData.videos.forEach((video, index) => {
    //   data.append(`videos[${index}]`, video);
    // });

    console.log(body);

    try {
      const response = await http.post("/api/workouts", { data: body });
      console.log("Workout created:", response.data);
    } catch (error) {
      console.error("Failed to create workout", error);
    } finally {
      setLoading(false);
      navigate("/Workouts");
    }
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          Create Workout
        </Typography>
      </Grid>

      {/* Workout Name */}
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Workout Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          required
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth required>
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            id="category"
            name="category"
            value={formData.category}
            label="Category"
            onChange={handleCategoryChange}
          >
            <MenuItem value="cardio">Cardio</MenuItem>
            <MenuItem value="fat loss">Fat Loss</MenuItem>
            <MenuItem value="weight training">Weight Training</MenuItem>{" "}
            {/* Added for completeness */}
          </Select>
        </FormControl>
      </Grid>

      {/* Image Upload */}
      <Grid item xs={12}>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
        />
        <Typography variant="body2">
          Upload multiple images (optional)
        </Typography>
      </Grid>

      {/* Image Preview */}
      {previewImages.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6">Selected Images</Typography>
          <Grid container spacing={2}>
            {previewImages.map((src, index) => (
              <Grid item key={index}>
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    src={src}
                    alt={`preview ${index}`}
                    width={100}
                    height={100}
                  />
                  <IconButton
                    size="small"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      background: "red",
                    }}
                    onClick={() => handleRemoveImage(index)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}

      {/* Video Upload */}
      <Grid item xs={12}>
        <input
          type="file"
          accept="video/*"
          multiple
          onChange={handleVideoUpload}
        />
        <Typography variant="body2">
          Upload multiple videos (optional)
        </Typography>
      </Grid>

      {/* Assigned Users (Multi-Select) */}
      {/* <Grid item xs={12}>
       <UserDropdown onSelectChange={handleSelectChange} selectedUsers={formData.assignedUsers}/>
      </Grid> */}

      {/* Submit Button */}
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Create Workout"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default WorkoutForm;
