import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Paper,
  Divider,
  Button,
  Tabs,
  Tab,
  Box,
  Typography,
  Avatar,
} from "@mui/material";
import {
  Person,
  FitnessCenter,
  InsertPhoto,
  CalendarToday,
  LocationCity,
  Language,
  Work,
  Bloodtype,
  Email,
} from "@mui/icons-material";
import { getUser } from "../../functions/users";
import { createOrRetrieveChat } from "../../functions/firebae";
import { useSelector } from "react-redux";
import Overview from "../../Components/UserDetails/Overview";
import CheckIns from "../../Components/UserDetails/CheckIns";
import Training from "../../Components/UserDetails/Training";
import Nutrition from "../../Components/UserDetails/Nutrition";
import Habits from "../../Components/UserDetails/Habits";
import Reports from "../../Components/UserDetails/Reports";
import Forms from "../../Components/UserDetails/Forms";
import Appointments from "../../Components/UserDetails/Appointments";
import Doctors from "../../Components/UserDetails/Doctors";
import Metrics from "../../Components/UserDetails/Metrics";
import Settings from "../../Components/UserDetails/Settings";
import { useDispatch } from "react-redux";
import { addUserDetailInReducer } from "../../redux/action/useraction";

function UserDetail() {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const mydata = useSelector((state) => state?.user?.me);
  const navigate = useNavigate();

  const userDetail = useSelector((state)=>state?.user?.userData);
  console.log(userDetail)

  useEffect(()=>{
    setUser(userDetail)
  },[userDetail])
  

  const [formData, setFormData] = useState({
    calories_goal: "",
    water_intake_goal: "",
    breakfast_meal: [],
    lunch_meal: [],
    dinner_meal: [],
  });

  const handleSelectChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      breakfast_meal: e.target.value,
    }));
  };

  const handleSelectChange2 = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      lunch_meal: e.target.value,
    }));
  };

  const handleSelectChange3 = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      dinner_meal: e.target.value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Fetch user data by ID
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUser = async () => {
        try {
         dispatch(addUserDetailInReducer(id));
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    fetchUser();
  }, [id]);

  const startchat = async () => {
    const senderEmail = mydata?.email;
    const receiverEmail = user?.email;
    try {
      const chatId = await createOrRetrieveChat(senderEmail, receiverEmail);
      navigate(`/chat/${chatId}`, {
        state: { chatId, senderEmail, receiverEmail },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  if (!user) return <p>Loading...</p>;

  return (
    <Box container>
      <Grid container direction="column">
        <Grid item xs={12}>
          <Box>
            <CardContent sx={{ p: 0 }}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  <Avatar
                    src={user.img?.url}
                    alt={user.username}
                    sx={{ width: 52, height: 52 }}
                  />
                  <Box ml={2}>
                    <Typography variant="h6">{user.username}</Typography>
                    <Typography color="text.secondary">{user.email}</Typography>
                  </Box>
                </Box>

                <Button variant="contained" color="primary" onClick={startchat}>
                  Start Chat
                </Button>
              </Box>
            </CardContent>
          </Box>
        </Grid>

        {/* Tabs Section */}
        <Grid item xs={12}>
          <Paper style={{ padding: 20 }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              sx={{ borderBottom: 1, borderColor: "divider" }}
            >
              <Tab label="Overview" />
              <Tab label="Check-ins" />
              <Tab label="Training" />
              <Tab label="Nutrition" />
              <Tab label="Habits" />
              <Tab label="Reports" />
              <Tab label="Forms" />
              <Tab label="Appointments" />
              <Tab label="Doctors" />
              <Tab label="Metrics" />
              <Tab label="Settings" />
            </Tabs>
            <Divider />
            <Box mt={2}>
              {activeTab === 0 && <Overview user={user} />}
              {activeTab === 1 && <CheckIns user={user} />}
              {activeTab === 2 && <Training user={user} />}
              {activeTab === 3 && <Nutrition user={user} />}
              {activeTab === 4 && <Habits user={user} />}
              {activeTab === 5 && <Reports user={user} />}
              {activeTab === 6 && <Forms user={user} />}
              {activeTab === 7 && <Appointments user={user} />}
              {activeTab === 8 && <Doctors user={user} />}
              {activeTab === 9 && <Metrics user={user} />}
              {activeTab === 10 && <Settings user={user} />}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default UserDetail;
