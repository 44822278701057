import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Box, Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const RecipeDropdown = ({ selectedRecieps, onSelectChange }) => {
    const recipes = useSelector((state) => state.user?.recipes);

    // Function to handle removing a selected recipe by clicking the close icon
    const handleRemoveRecipe = (recipeId) => {
        const updatedSelectedRecipes = selectedRecieps.filter((id) => id !== recipeId);
        onSelectChange({ target: { value: updatedSelectedRecipes } }); // Simulate Select's onChange behavior
    };


    const navigate= useNavigate()
    return (
        <>
            <FormControl fullWidth margin="normal" size="small">
                <InputLabel>Select recipes</InputLabel>
                <Select
                    multiple
                    value={selectedRecieps}
                    onChange={onSelectChange}
                    label="Select recipes"
                    renderValue={(selected) =>
                        selected?.map((recipeId) => {
                            const item = recipes?.find((u) => u.id === recipeId);
                            return item ? item?.attributes?.name : '';
                        }).join(', ')
                    }
                >
                    {recipes?.map((recipe) => (
                        <MenuItem key={recipe.id} value={recipe.id}>
                            <Checkbox checked={selectedRecieps?.includes(recipe.id)} />
                            <ListItemText primary={recipe?.attributes?.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Display selected recipes below the dropdown with close icons */}
            <Box display="flex" flexWrap="wrap" mt={1}>
                {selectedRecieps?.map((recipeId) => {
                    const recipe = recipes?.find((u) => u.id === recipeId);
                    return recipe ? (
                        <Chip
                        onClick={()=>{navigate(`/EditRecipe/${recipeId}`)}}
                            key={recipeId}
                            label={recipe?.attributes?.name}
                            onDelete={() => handleRemoveRecipe(recipeId)}
                            deleteIcon={<CloseIcon />}
                            style={{ margin: '5px' }}
                        />
                    ) : null;
                })}
            </Box>
        </>
    );
};

export default RecipeDropdown;
