// src/App.js
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  IconButton,
  Badge,
  Box,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Sidebar from "./Components/SideDrawer";
import Login from "./Pages/Login";
import AuthWrapper from "./Components/AuthWrapper";
import { io } from "socket.io-client";
import { toast } from "react-toastify";
import ManagePlans from "./Pages/ManagePlans";
import Users from "./Pages/users/Users";
import NotificationsPage from "./Pages/Notifications";
import Recipes from "./Pages/Recipes/Recipes";
import CreateRecipeform from "./Pages/Recipes/Addrecipes";
import UserDetail from "./Pages/users/UserDetail";
import Doctors from "./Pages/doctors/Doctors";
import CreateDoctor from "./Pages/doctors/AddDoctor";
import { prodUrl } from "./utils/http";
import Workouts from "./Pages/workout/Workouts";
import WorkoutForm from "./Pages/workout/Addworkout";
import EditDoctor from "./Pages/doctors/EditDoctor";
import EditRecipe from "./Pages/Recipes/EditRecipes";
import ChatDetail from "./Pages/Inbox/Chat";
import Inbox from "./Pages/Inbox/Inbox";
import { useDispatch, useSelector } from "react-redux";
import { getMydata } from "./redux/action/useraction";
import FormBuilder from "./Pages/Form/Formbuilder";
import Home from "./Pages/home/Home";
import Templates from "./Pages/Form/AllTempaltes";
import Plans from "./Pages/plans/Plans";
import Plansdetail from "./Pages/plans/Plansdetail";
import EditWorkout from "./Pages/workout/EditWorkout";

const socket = io(prodUrl, {
  path: "/socket.io",
  transports: ["websocket"],
});

function App() {
  const [isConnected, setIsConnected] = useState(false);
  const [email] = useState("sourabhsahu@gmailc.oom");
  const dispatch = useDispatch();
  const mydata = useSelector((state) => state?.user?.me);
  const navigate = useNavigate(); // useNavigate hook for navigation

  useEffect(() => {
    dispatch(getMydata());
  }, [dispatch]);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("Connected to Socket.IO server");
      setIsConnected(true);

      if (email) {
        socket.emit("register", email);
        console.log("Registering user with email:", email);
      }
    });

    const handleNotification = (msg) => {
      console.log(msg, "notification");
      toast.success(msg);
    };

    const handleConfirmation = (msg) => {
      console.log("Confirmation received:", msg);
      toast.success("Connected to the network");
    };

    socket.on("notification", handleNotification);
    socket.on("confirmation", handleConfirmation);

    return () => {
      socket.off("notification", handleNotification);
      socket.off("confirmation", handleConfirmation);
      socket.disconnect();
    };
  }, [email]);

  const handleNotificationClick = () => {
    navigate("/Notifications"); // Navigate to Notifications page
  };

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/*"
        element={
          <AuthWrapper>
            {/* Top Navigation Bar */}
            <AppBar
              position="fixed"
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: "black",
              }}
            >
              <Toolbar
                sx={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography variant="h6" noWrap sx={{ color: "white" }}>
                  PREVEALTH CRM
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="body1"
                    sx={{ marginRight: "10px", color: "grey" }}
                  >
                    {mydata?.email}
                  </Typography>
                  {/* Notification Icon */}
                  <IconButton
                    color="inherit"
                    onClick={handleNotificationClick}
                    sx={{ marginRight: "10px" }}
                  >
                    <Badge badgeContent={4} color="secondary">
                      {/* Replace 4 with dynamic unread count */}
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                  <Avatar sx={{ bgcolor: "white", color: "black" }}>
                    <AccountCircleIcon />
                  </Avatar>
                </Box>
              </Toolbar>
            </AppBar>

            <Box sx={{ display: "flex", marginTop: "64px" }}>
              <Sidebar />

              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  p: 3,
                  width: { sm: `calc(100% - ${240}px)` },
                  overflowX: "hidden",
                }}
              >
                <Routes>
                  <Route path="/Home" element={<Home />} />
                  <Route path="/" element={<Home />} />
                  <Route path="/Users" element={<Users />} />
                  <Route path="/Inbox" element={<Inbox />} />
                  <Route path="/Chat/:chatId" element={<ChatDetail />} />
                  <Route path="/UserDetail/:id" element={<UserDetail />} />
                  <Route path="/ManagePlans" element={<Plans/>} />
                    <Route path="/CreatePlan" element={<Plansdetail/>} />
                    <Route path="/EditPlan/:id" element={<Plansdetail/>} />
                    <Route path="/PlanDetail/:id" element={<Plansdetail/>} />
                  <Route
                    path="/Notifications"
                    element={<NotificationsPage />}
                  />
                  <Route path="/Workouts" element={<Workouts />} />
                  <Route path="/CreateWorkout" element={<WorkoutForm />} />
                  <Route path="/EditWorkout/:id" element={<EditWorkout />} />
                  <Route path="/Workout/:id" element={<WorkoutForm />} />
                  <Route path="/Doctors" element={<Doctors />} />
                  <Route path="/Doctor/:id" element={<CreateDoctor />} />
                  <Route path="/CreateDoctor" element={<CreateDoctor />} />
                  <Route path="/EditDoctor/:id" element={<EditDoctor />} />
                  <Route path="/Recipes" element={<Recipes />} />
                  <Route path="/EditRecipe/:id" element={<EditRecipe />} />
                  <Route path="/AddRecipe" element={<CreateRecipeform />} />
                  <Route path="/Forms" element={<FormBuilder />} />
                  <Route path="/Form/FormBuilder" element={<FormBuilder />} />
                  <Route
                    path="/Form/FormBuilder/:id"
                    element={<FormBuilder />}
                  />
                  <Route path="/Form/Template" element={<Templates />} />
                  {/* 
                    <Route path="/Contact" element={<Contact />} />
                    <Route path="/Help" element={<Help />} /> */}
                </Routes>
              </Box>
            </Box>
          </AuthWrapper>
        }
      />
    </Routes>
  );
}

export default App;
