import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, Chip, Box, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const WorkoutsDropdown = ({ selectedWorkouts, onSelectChange }) => {
    const workouts = useSelector((state) => state.user?.workouts);

    // Function to handle removing a selected workout by clicking the close icon
    const handleRemoveWorkout = (workoutId) => {
        const updatedSelectedWorkouts = selectedWorkouts.filter((id) => id !== workoutId);
        onSelectChange({ target: { value: updatedSelectedWorkouts } }); // Simulate Select's onChange behavior
    };
    const navigate = useNavigate()

    return (
        <>
            <FormControl fullWidth margin="normal" size="small">
                <InputLabel>Select Workouts</InputLabel>
                <Select
                    multiple
                    value={selectedWorkouts}
                    onChange={onSelectChange}
                    label="Select Workouts"
                    renderValue={(selected) =>
                        selected?.map((workoutId) => {
                            const item = workouts?.find((u) => u.id === workoutId);
                            return item ? item?.attributes?.name : '';
                        }).join(', ')
                    }
                >
                    {workouts?.map((workout) => (
                        <MenuItem key={workout.id} value={workout.id}>
                            <Checkbox checked={selectedWorkouts?.includes(workout.id)} />
                            <ListItemText primary={workout?.attributes?.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            {/* Display selected workouts with close icon */}
            <Box display="flex" flexWrap="wrap" mt={1}>
                {selectedWorkouts?.map((workoutId) => {
                    const workout = workouts?.find((u) => u.id === workoutId);
                    return workout ? (
                        <Chip
                            onClick={()=>navigate(`/Workout/${workout.id}`)}
                            key={workoutId}
                            label={workout?.attributes?.name}
                            onDelete={() => handleRemoveWorkout(workoutId)}
                            deleteIcon={<CloseIcon />}
                            style={{ margin: '5px' }}
                        />
                    ) : null;
                })}
            </Box>
        </>
    );
};

export default WorkoutsDropdown;
