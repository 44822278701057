import axios from "axios";

export const uploadimage = async (image) => {
  const data = new FormData();
  data.append("files", image, image.name);
  try {
    const res = await axios.post(
      "https://api-prevealth.saralgroups.com/api/upload",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    console.log(res?.data[0], "image id");
    // const arr = res?.data?.map((item)=>item?.id);
    return res?.data[0];
  } catch (error) {
    console.log(error);
  }
};

export const uploadimageMultiple = async (images) => {
  const data = new FormData();

  // Loop through the images array and append each file to the FormData object
  images.forEach((image, index) => {
    data.append("files", image, image.name); // Append each image to FormData
  });

  try {
    const res = await axios.post(
      "https://api-prevealth.saralgroups.com/api/upload",
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    // Log response and extract image ids
    console.log(res?.data, "image id");
    const arr = res?.data?.map((item) => item?.id); // Get array of image IDs
    return arr;
  } catch (error) {
    console.log(error);
  }
};
