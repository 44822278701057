import React, { useEffect, useState } from 'react';
import { Container, TextField, MenuItem, Button, IconButton, Box } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteIcon from '@mui/icons-material/Delete';
import { http } from '../../utils/http';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

// Types of questions
const QUESTION_TYPES = [
  { label: 'Short Answer', value: 'short_answer' },
  { label: 'Single Choice', value: 'single_choice' },
  { label: 'Multiple Choice', value: 'multiple_choice' },
];

// Component
const FormBuilder = () => {
  const [questions, setQuestions] = useState([]);
  const { id } = useParams(); // Get the doctor ID from the URL

  
  const fetchDoctorData = async () => {
    try {
      const response = await http.get(
        `/api/formbuilders/${id}?populate=*`
      );
      setQuestions(response?.data?.data?.attributes?.questions) // Adjust based on your API response structure

    } catch (error) {
      console.error("Error fetching doctor data:", error);
    }
  };

  useEffect(()=>{
   fetchDoctorData();
  },[]);
  const submit = async()=>{
    try {
        const {data} = await http.post("/api/formbuilders",{data:{
            questions:questions,

        }});
        toast.success('Successfully added')
    } catch (error) {
        console.log(error)
        toast.error("Network error")
    }
  }
  const editAndSave = async()=>{
    try {
        const {data} = await http.put(`/api/formbuilders/${id}`,{data:{
            questions:questions,

        }});
        toast.success('Successfully edited')
    } catch (error) {
        console.log(error)
        toast.error("Network error")
    }
  }

  // Add a new question
  const addQuestion = () => {
    setQuestions([
      ...questions,
      {
        id: `q-${questions.length + 1}`,
        question: '',
        type: 'short_answer',
        options: [],
      },
    ]);
  };

  // Handle input changes
  const handleInputChange = (index, key, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][key] = value;
    setQuestions(updatedQuestions);
  };

  // Handle drag and drop
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const updatedQuestions = [...questions];
    const [removed] = updatedQuestions.splice(result.source.index, 1);
    updatedQuestions.splice(result.destination.index, 0, removed);
    setQuestions(updatedQuestions);
  };

  // Delete question
  const deleteQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  return (
    <Container>
      <h2>Form Builder</h2>
      <Button variant="contained" color="primary" onClick={addQuestion}>
        Add Question
      </Button>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="questions">
          {(provided) => (
            <Box ref={provided.innerRef} {...provided.droppableProps} sx={{ marginTop: 2 }}>
              {questions.map((question, index) => (
                <Draggable key={question.id} draggableId={question.id} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: 2,
                        marginBottom: 2,
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                      }}
                    >
                      <TextField
                        label="Question"
                        value={question.question}
                        onChange={(e) => handleInputChange(index, 'question', e.target.value)}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                      />

                      <TextField
                        label="Question Type"
                        select
                        value={question.type}
                        onChange={(e) => handleInputChange(index, 'type', e.target.value)}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                      >
                        {QUESTION_TYPES.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>

                      {question.type === 'single_choice' || question.type === 'multiple_choice' ? (
                        <Box>
                          {question.options.map((option, optIndex) => (
                            <TextField
                              key={optIndex}
                              label={`Option ${optIndex + 1}`}
                              value={option}
                              onChange={(e) => {
                                const updatedOptions = [...question.options];
                                updatedOptions[optIndex] = e.target.value;
                                handleInputChange(index, 'options', updatedOptions);
                              }}
                              fullWidth
                              sx={{ marginBottom: 1 }}
                            />
                          ))}
                          <Button
                            variant="outlined"
                            onClick={() => {
                              const updatedOptions = [...question.options, ''];
                              handleInputChange(index, 'options', updatedOptions);
                            }}
                          >
                            Add Option
                          </Button>
                        </Box>
                      ) : null}

                      <IconButton onClick={() => deleteQuestion(index)}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      {id?<Button 
         onClick={()=>editAndSave()}
          sx={{ marginTop: "10px" }}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
        >
          Edit & Save
        </Button>:<Button 
         onClick={()=>submit()}
          sx={{ marginTop: "10px" }}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
        >
          Submit
        </Button>}
    </Container>
  );
};

export default FormBuilder;
