import React, { useEffect, useState } from 'react';
import { http } from '../../utils/http';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Typography, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { uploadimage } from '../../functions/upload';

function Plansdetail() {
  const navigate = useNavigate();
  const { id } = useParams();
  
  // Ensure formData has a structure that won't cause undefined errors
  const [formData, setFormData] = useState({
    attributes: {
      plan_type: '',
      category_tag: '',
      tag: '',
      compo1: {
        title: '',
        price_compo: [{ price: '', name: '', price_description: '', points: [{ point: '' }] }]
      }
    }
  });

  const [categoryTagOptions, setCategoryTagOptions] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [existed, setExisted] = useState(false);
  const [bannerImageUrl, setBannerImageUrl] = useState('');

  const handlePriceCompoChange = (index, field, value, pointIndex = null) => {
    const newPriceCompo = [...formData.attributes.compo1.price_compo];
  
    // If we are updating a specific point within a price_compo
    if (pointIndex !== null && field.startsWith('points')) {
      const newPoints = [...newPriceCompo[index].points];
      newPoints[pointIndex] = { ...newPoints[pointIndex], point: value };
      newPriceCompo[index] = { ...newPriceCompo[index], points: newPoints };
    } else {
      newPriceCompo[index] = {
        ...newPriceCompo[index],
        [field]: value,
      };
    }
  
    setFormData({
      ...formData,
      attributes: {
        ...formData.attributes,
        compo1: {
          ...formData.attributes.compo1,
          price_compo: newPriceCompo,
        },
      },
    });
  };
  
  const handleAddPriceCompo = () => {
    if (formData.attributes.compo1.price_compo.length < 3) {
      setFormData({
        ...formData,
        attributes: {
          ...formData.attributes,
          compo1: {
            ...formData.attributes.compo1,
            price_compo: [
              ...formData.attributes.compo1.price_compo,
              { price: '', name: '', price_description: '', points: [{ point: '' }] },
            ],
          },
        },
      });
    } else {
      alert('You can only add up to 3 price components.');
    }
  };

  const handleRemovePriceCompo = (index) => {
    const newPriceCompo = formData.attributes.compo1.price_compo.filter(
      (_, compoIndex) => compoIndex !== index
    );
    setFormData({
      ...formData,
      attributes: {
        ...formData.attributes,
        compo1: {
          ...formData.attributes.compo1,
          price_compo: newPriceCompo,
        },
      },
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImagefile(file)
    if (file) {
      setFormData({
        ...formData,
        attributes: {
          ...formData.attributes,
          bannerImage: file,
        }
      });
      setBannerImageUrl(URL.createObjectURL(file)); // Generate URL for image preview
    }
  };
  const [imagefile,setImagefile]=useState('');
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const image = await uploadimage(imagefile);
      const response = await http.post(`/api/plans`,{data:formData});
      toast.success('Data sent successfully!');
      console.log(response?.data);
    } catch (error) {
      console.error('Error sending data:', error);
    //   alert('Failed to send data.');
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      const response = await http.put(`/api/plans/${id}`, { data: formData });
      console.log(response);
      toast.success('Data updated successfully!');
    } catch (error) {
      console.error('Error updating data:', error);
      toast.error('Failed to update data.');
    }
  };

  const getdata = async () => {
    try {
      if (id) {
        const response = await http.get(`/api/plans/${id}?populate=deep`);
        setFormData(response?.data?.data || {});
      }
      const response2 = await http.get(`/api/trending-tags?populate=*`);
      const response3 = await http.get(`/api/category-tags?populate=*`);
      setTagOptions(response2?.data?.data || []);
      setCategoryTagOptions(response3?.data?.data || []);
      setExisted(true);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Error fetching data');
    }
  };

  useEffect(() => {
    getdata();
  }, [id]);

  return formData ? (
    <Box sx={{ p: 3 }}>
      <img 
                src={bannerImageUrl} // Default image URL if no image is selected

      alt="Banner" style={{ width: '100%', height: 'auto' }} />

      <form onSubmit={id ? handleEdit : handleSubmit}>
      <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ marginBottom: '16px' }}
        />
        {/* Plan Type Field */}
        <TextField
          label="Plan Type"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData?.attributes?.plan_type || ''}
          onChange={(e) =>
            setFormData({
              ...formData,
              attributes: {
                ...formData.attributes,
                plan_type: e.target.value,
              },
            })
          }
        />

        {/* Category Tag Dropdown */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Category Tag</InputLabel>
          <Select
            label="Category Tag"
            value={formData?.attributes?.category_tag || ''}
            onChange={(e) =>
              setFormData({
                ...formData,
                attributes: {
                  ...formData.attributes,
                  category_tag: e.target.value,
                },
              })
            }
          >
            {categoryTagOptions.map((option) => (
              <MenuItem key={option?.id} value={option?.attributes?.name}>
                {option?.attributes?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Tag Dropdown */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Tag</InputLabel>
          <Select
            label="Tag"
            value={formData?.attributes?.tag || ''}
            onChange={(e) =>
              setFormData({
                ...formData,
                attributes: {
                  ...formData.attributes,
                  tag: e.target.value,
                },
              })
            }
          >
            {tagOptions.map((option) => (
              <MenuItem key={option?.id} value={option?.attributes?.name}>
                {option?.attributes?.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Title Field */}
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formData?.attributes?.compo1?.title || ''}
          onChange={(e) =>
            setFormData({
              ...formData,
              attributes: {
                ...formData.attributes,
                compo1: {
                  ...formData.attributes.compo1,
                  title: e.target.value,
                },
              },
            })
          }
        />

        {/* Price Components Section */}
        <Typography variant="h6" sx={{ mt: 2 }}>
          Price Types
        </Typography>
        {formData?.attributes?.compo1?.price_compo?.map((compo, index) => (
          <Box key={compo?.id || index} sx={{ mb: 2 }}>
            <TextField
              label="Price"
              variant="outlined"
              fullWidth
              margin="normal"
              value={compo?.price || ''}
              onChange={(e) => handlePriceCompoChange(index, 'price', e.target.value)}
            />
            <TextField
              label="Price Name"
              variant="outlined"
              fullWidth
              margin="normal"
              value={compo?.name || ''}
              onChange={(e) => handlePriceCompoChange(index, 'name', e.target.value)}
            />
            <TextField
              label="Price Description"
              variant="outlined"
              fullWidth
              margin="normal"
              value={compo?.price_description || ''}
              onChange={(e) => handlePriceCompoChange(index, 'price_description', e.target.value)}
            />

            {/* Handle Points */}
            {compo?.points?.map((point, pointIndex) => (
              <TextField
                key={point?.id || pointIndex}
                label={`Point ${pointIndex + 1}`}
                variant="outlined"
                fullWidth
                margin="normal"
                value={point?.point || ''}
                onChange={(e) =>
                  handlePriceCompoChange(index, `points.${pointIndex}.point`, e.target.value)
                }
              />
            ))}

            {/* Remove Price Component Button */}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleRemovePriceCompo(index)}
              sx={{ mt: 1 }}
            >
              Remove Price Component
            </Button>
          </Box>
        ))}

        {/* Add Price Component Button */}
        {formData?.attributes?.compo1?.price_compo?.length < 3 && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddPriceCompo}
            sx={{ mt: 2 }}
          >
            Add Price Component
          </Button>
        )}

        {/* Submit Button */}
        <Button type="submit" variant="contained" color="primary" sx={{ ml: 20,mt:2 }}>
          Submit
        </Button>
      </form>
    
    </Box>
  ) : (
    <h2>Loading...</h2>
  );
}

export default Plansdetail;
