import React, { useEffect, useState } from "react";
import { Avatar } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { getAlluser } from "../../functions/users";
import { useDispatch, useSelector } from "react-redux";
import { addUsersInReducer } from "../../redux/action/useraction";

function Users() {
  const users = useSelector((state) => state.user);
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate(); // Initialize useNavigate
  const dispatch = useDispatch();

  useEffect(() => {
    setData(users?.userArray);
  }, [users?.userArray]);
  // Fetch users from API
  const getusers = async () => {
    try {
      const usersData = await dispatch(addUsersInReducer());
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getusers();
  }, []);

  // Define table columns
  const columns = [
    {
      accessorKey: "img",
      header: "Image",
      enableSorting: false,
      enableColumnActions: false,
      Cell: ({ cell }) =>
        cell.row.original?.img?.url ? (
          <img
            style={{
              height: 40,
              width: 40,
              borderRadius: 40,
              backgroundColor: "black",
            }}
            src={cell.row.original?.img?.url}
            alt="profile"
          />
        ) : (
          <Avatar alt="imgs" src={cell.getValue()} />
        ),
    },
    {
      accessorKey: "username",
      header: "Name",
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "email",
      header: "Email",
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "gender",
      header: "Gender",
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "weight",
      header: "Weight",
      enableSorting: false,
      enableColumnActions: false,
    },
    {
      accessorKey: "height",
      header: "Height",
      enableSorting: false,
      enableColumnActions: false,
    },
  ];

  if (loading) {
    return <p>Loading...</p>;
  }

  // Handle row click
  const handleRowClick = (row) => {
    const userId = row.original.id; // Assuming each user has an `id`
    navigate(`/UserDetail/${userId}`); // Navigate to the user details page
  };

  return (
    <MaterialReactTable
      columns={columns}
      data={data || []} // Ensure data is an array
      initialState={{ pageSize: 5 }} // Optional: set the initial page size
      enablePagination
      enableSorting
      muiTableBodyRowProps={({ row }) => ({
        onClick: () => handleRowClick(row),
        style: { cursor: "pointer" }, // Add a pointer cursor on hover
      })} // Make the entire row clickable
    />
  );
}

export default Users;
