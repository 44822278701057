import React, { useEffect, useState } from "react";
import { http } from "../../utils/http";
import { Avatar, Button, IconButton } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

function Recipes() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const getRecipes = async () => {
    try {
      const response = await http.get("/api/recipes?populate=deep");
      setData(response.data?.data || []);
      console.log(response.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRecipes();
  }, []);

  const columns = [
    {
      accessorKey: "attributes.name",
      header: "Name",
      size: 200, // Set a specific width for this column
    },
    {
      accessorKey: "attributes.img",
      header: "Image",
      Cell: ({ cell }) =>
        cell.row.original.attributes?.img?.data?.[0].attributes?.url ? (
          <img
            style={{
              height: 40,
              width: 40,
              borderRadius: 40,
              backgroundColor: "black",
            }}
            src={cell.row.original.attributes?.img?.data?.[0].attributes?.url}
            alt="Recipe"
          />
        ) : (
          <Avatar
            alt={cell.row.original.attributes.dr_name}
            src={cell.getValue()}
          />
        ),
    },
    {
      accessorKey: "attributes.nutrient_table",
      header: "Nutrients",
      Cell: ({ cell }) => (
        <div>
          {cell.getValue().map((nutrient, index) => (
            <div key={index}>{nutrient.name}</div>
          ))}
        </div>
      ),
      size: 200, // Set a specific width for this column
    },
    {
      accessorKey: "attributes.Ingredients_with_it_smeasure",
      header: "Ingredients",
      Cell: ({ cell }) => (
        <div>
          {cell.getValue().map((ingredient, index) => (
            <div key={index}>{ingredient.point}</div>
          ))}
        </div>
      ),
      size: 200, // Set a specific width for this column
    },
    {
      accessorKey: "attributes.method_of_preparation",
      header: "Method of Preparation",
      Cell: ({ cell }) => (
        <div>
          {cell.getValue().map((step, index) => (
            <div key={index}>
              {step.point.length > 30
                ? `${step.point.substring(0, 30)}...`
                : step.point}
            </div>
          ))}
        </div>
      ),

      size: 200, // Set a specific width for this column
    },
    {
      accessorKey: "actions",
      header: "",
      enableSorting: false,
      enableColumnActions: false,
      size: 100, // Set a specific width for the actions column
      Cell: ({ row }) => (
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "2px" }}
        >
          <IconButton onClick={() => handleEdit(row.original.id)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleEdit = (id) => {
    navigate(`/EditRecipe/${id}`); // Navigate to the EditRecipe page with the recipe's ID
  };

  const handleDelete = async (id) => {
    try {
      await http.delete(`/api/recipes/${id}`);
      toast.success("Recipe deleted successfully");
      getRecipes();
    } catch (error) {
      console.error("Error deleting recipe:", error);
      toast.error("Error deleting recipe");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button
        sx={{ backgroundColor: "black", marginBottom: "10px" }}
        onClick={() => navigate("/AddRecipe")}
        variant="contained"
        component="label"
      >
        Create Recipe
      </Button>
      <MaterialReactTable
        columns={columns}
        data={data}
        initialState={{ pageSize: 5 }} // Optional: set the initial page size
        enablePagination
        enableSorting
        muiTableBodyRowProps={({ row }) => ({
          style: { cursor: "pointer" },
        })}
      />
    </div>
  );
}

export default Recipes;
