import { http } from "../utils/http"

export const getDoctros = async()=>{

try {
    const data = await http.get("/api/doctors-consultants?populate=image");
    console.log(data);
    return data
} catch (error) {
    console.log(error);
}

}


