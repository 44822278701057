// EditWorkout.js
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useSelector } from "react-redux";
import { uploadimage, uploadimageMultiple } from "../../functions/upload";
import UserDropdown from "../../Components/UserDropdown";
import CloseIcon from "@mui/icons-material/Close";
import { http } from "../../utils/http";
import { useNavigate, useParams } from "react-router-dom";

const EditWorkout = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    category: "",
    images: [],
    existingImages: [],
    videos: [],
    existingVideos: [],
    assignedUsers: [],
    createdBy: "",
  });

  const user = useSelector((state) => state?.user?.userArray);
  const [users, setUsers] = useState(user);
  const [loading, setLoading] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [previewExistingImages, setPreviewExistingImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWorkout = async () => {
      setLoading(true);
      try {
        const response = await http.get(`/api/workouts/${id}`);
        const data = response.data.data.attributes;
        setFormData({
          name: data.name || "",
          description: data.description || "",
          category: data.category || "",
          images: [],
          existingImages: data.img || [],
          videos: [],
          existingVideos: data.videos || [],
          assignedUsers: data.users || [],
          createdBy: data.createdBy || "",
        });

        if (data.img && Array.isArray(data.img)) {
          setPreviewExistingImages(data.img);
        }
      } catch (error) {
        console.error("Failed to fetch workout data", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkout();
  }, [id]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleCategoryChange = (e) => {
    setFormData({
      ...formData,
      category: e.target.value,
    });
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);

    setFormData({
      ...formData,
      images: files,
    });

    const imagePreviews = files.map((file) => URL.createObjectURL(file));
    setPreviewImages((prev) => [...prev, ...imagePreviews]);
  };

  const handleRemoveNewImage = (index) => {
    const updatedImages = [...formData.images];
    const updatedPreviews = [...previewImages];

    updatedImages.splice(index, 1);
    updatedPreviews.splice(index, 1);

    setFormData({
      ...formData,
      images: updatedImages,
    });

    setPreviewImages(updatedPreviews);
  };

  const handleRemoveExistingImage = (index) => {
    const updatedExistingImages = [...formData.existingImages];
    const updatedExistingPreviews = [...previewExistingImages];

    updatedExistingImages.splice(index, 1);
    updatedExistingPreviews.splice(index, 1);

    setFormData({
      ...formData,
      existingImages: updatedExistingImages,
    });

    setPreviewExistingImages(updatedExistingPreviews);
  };

  const handleVideoUpload = (e) => {
    setFormData({
      ...formData,
      videos: [...e.target.files],
    });
  };

  const handleSelectChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      assignedUsers: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      name: formData.name,
      description: formData.description,
      create_by: localStorage.getItem("userId"),
      users: formData.assignedUsers,
      category: formData.category,
      img: formData.existingImages,
      videos: formData.existingVideos,
    };

    try {
      if (formData.images.length > 0) {
        const newImageIds = await uploadimageMultiple(formData.images);
        body.img = [...body.img, ...newImageIds];
      }

      if (formData.videos.length > 0) {
        const newVideoIds = await uploadimageMultiple(formData.videos);
        body.videos = [...body.videos, ...newVideoIds];
      }

      const response = await http.put(`/api/workouts/${id}`, { data: body });
      console.log("Workout updated:", response.data);

      navigate("/Workouts");
    } catch (error) {
      console.error("Failed to update workout", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading && !formData.name) {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "50vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h4" align="center">
          Edit Workout
        </Typography>
      </Grid>

      {/* Workout Name */}
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Workout Name"
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          required
          multiline
          rows={4}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth required>
          <InputLabel id="category-label">Category</InputLabel>
          <Select
            labelId="category-label"
            id="category"
            name="category"
            value={formData.category}
            label="Category"
            onChange={handleCategoryChange}
          >
            <MenuItem value="cardio">Cardio</MenuItem>
            <MenuItem value="fat loss">Fat Loss</MenuItem>
            <MenuItem value="weight training">Weight Training</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* Image Upload */}
      <Grid item xs={12}>
        <Typography variant="h6">Upload New Images</Typography>
        <input
          type="file"
          accept="image/*"
          multiple
          onChange={handleImageUpload}
        />
        <Typography variant="body2">
          Upload multiple images (optional)
        </Typography>
      </Grid>

      {previewExistingImages.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6">Existing Images</Typography>
          <Grid container spacing={2}>
            {previewExistingImages.map((src, index) => (
              <Grid item key={index}>
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    src={
                      typeof src === "string" && src.startsWith("http")
                        ? src
                        : URL.createObjectURL(src)
                    }
                    alt={`existing preview ${index}`}
                    width={100}
                    height={100}
                  />
                  <IconButton
                    size="small"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      background: "red",
                    }}
                    onClick={() => handleRemoveExistingImage(index)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}

      {previewImages.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="h6">Selected New Images</Typography>
          <Grid container spacing={2}>
            {previewImages.map((src, index) => (
              <Grid item key={index}>
                <div style={{ position: "relative", display: "inline-block" }}>
                  <img
                    src={src}
                    alt={`new preview ${index}`}
                    width={100}
                    height={100}
                  />
                  <IconButton
                    size="small"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      background: "red",
                    }}
                    onClick={() => handleRemoveNewImage(index)}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}

      {/* Video Upload */}
      <Grid item xs={12}>
        <Typography variant="h6">Upload New Videos</Typography>
        <input
          type="file"
          accept="video/*"
          multiple
          onChange={handleVideoUpload}
        />
        <Typography variant="body2">
          Upload multiple videos (optional)
        </Typography>
      </Grid>

      {/* Submit Button */}
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Update Workout"}
        </Button>
      </Grid>
    </Grid>
  );
};

export default EditWorkout;
