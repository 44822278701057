import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
// import { db } from './firebase.config'; // Adjust the import path accordingly
// import { db } from '../../firebase.config';
import { db } from '../firebase.config';
export const generateChatId = (email1, email2) => {
    const sortedEmails = [email1, email2].sort();
    return `${sortedEmails[0]}_${sortedEmails[1]}`;
  };
export const createOrRetrieveChat = async (senderEmail, receiverEmail) => {
  const chatId = generateChatId(senderEmail, receiverEmail);
  const chatDocRef = doc(db, 'chats', chatId);
  const chatDoc = await getDoc(chatDocRef);

  if (!chatDoc.exists()) {
    await setDoc(chatDocRef, {
      participants: [senderEmail, receiverEmail],
      createdAt: serverTimestamp(),
    });
  }

  return chatId;
};


  