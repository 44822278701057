import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import { http } from '../../utils/http';
import WorkoutsDropdown from '../WorkoutDropdown';

const Training = () => {
  // State for workouts
  const [selectedWorkouts, setSelectedWorkouts] = useState([]);
  const userdetail = useSelector((state) => state.user.userData?.check_in_entry);

  // Set initial values for workouts from user details
  useEffect(() => {
    setSelectedWorkouts(userdetail?.workouts?.map((workout) => workout?.id) || []);
  }, [userdetail]);

  // Update user data with selected workouts
  const updateuserData = async () => {
    try {
      const obj = {
        workouts: selectedWorkouts,
      };
      await http.put(`/api/users/${userdetail?.id}`, { work: obj });
      toast.success('Data saved successfully');
    } catch (error) {
      console.error('Training update error:', error);
      toast.warn('Network error');
    }
  };

  // Handle the selection change for workouts
  const handleSelectChange = (selectedWorkouts) => {
    setSelectedWorkouts(selectedWorkouts);
  };

  return (
    <div style={{ display: "flex", flexDirection: 'column' }}>
      <h2>Training</h2>

      <div>
        <p>Workouts</p>
        <WorkoutsDropdown
          selectedWorkouts={selectedWorkouts}
          onSelectChange={(e) => handleSelectChange(e.target.value)}
        />
      </div>

      <Button
        onClick={updateuserData}
        sx={{ marginTop: "10px" }}
        type="submit"
        variant="contained"
        color="primary"
      >
        Save
      </Button>
    </div>
  );
};

export default Training;
