import React, { useEffect, useState } from "react";
import { http } from "../../utils/http";
import { Button, IconButton } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";

function Plans() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const getplans = async () => {
    try {
      const response = await http.get("/api/plans?populate=compo1");
      setData(response.data?.data || []);
      console.log(response.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getplans();
  }, []);

  const columns = [
    {
      accessorKey: "attributes.compo1.title",
      header: "Name",
      size: 200, // Set a specific width for this column
    },
    {
        accessorKey: "attributes.plan_type",
        header: "Type",
        size: 200, // Set a specific width for this column
      },
      {
        accessorKey: "actions",
        header: "",
        enableSorting: false,
        enableColumnActions: false,
        size: 100, // Set a specific width for the actions column
        Cell: ({ row }) => (
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "2px" }}
          >
            <IconButton onClick={() => handleEdit(row.original.id)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleDelete(row.original.id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      },

  ];

  const handleEdit = (id) => {
    navigate(`/EditPlan/${id}`); // Navigate to the EditRecipe page with the recipe's ID
  };

  const handleDelete = async (id) => {
    try {
      await http.delete(`/api/plans/${id}`);
      toast.success("Plan deleted successfully");
      getplans();
    } catch (error) {
      console.error("Error deleting recipe:", error);
      toast.error("Error deleting plan");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button
        sx={{ backgroundColor: "black", marginBottom: "10px" }}
        onClick={() => navigate("/CreatePlan")}
        variant="contained"
        component="label"
      >
        Add New Plan
      </Button>
      <MaterialReactTable
        columns={columns}
        data={data}
        initialState={{ pageSize: 5 }} // Optional: set the initial page size
        enablePagination
        enableSorting
        muiTableBodyRowProps={({ row }) => ({
          style: { cursor: "pointer" },
        })}
      />
    </div>
  );
}

export default Plans;
