import React, { useEffect, useState } from 'react';
import RecipeDropdown from '../RecipesDropdown';
import { useSelector } from 'react-redux';
import { updatecheckin } from '../../functions/users';
import { Button } from '@mui/material';
import { toast } from 'react-toastify';

const Nutrition = () => {
  // State for each meal type
  const [lunchMeals, setLunchMeals] = useState([]);
  const [breakfastMeals, setBreakfastMeals] = useState([]);
  const [dinnerMeals, setDinnerMeals] = useState([]);
  const userdetail = useSelector((state) => state.user.userData?.check_in_entry);

  useEffect(()=>{
  setLunchMeals(userdetail?.lunch_meals?.map((item)=>{return item?.id}));
  setDinnerMeals(userdetail?.dinners?.map((item)=>{return item?.id}));
  },[userdetail])
  const updateuserData = async () => {
    try {
      const obj = {
        lunch_meal: lunchMeals,
        dinner: dinnerMeals,
        breakefast_meal: breakfastMeals
      };
      const { data } = await updatecheckin(userdetail?.id,userdetail?.check_in_entry?.data?.id,obj);
      toast.success('Data saved successfully');
    } catch (error) {
      console.log(error, 'nutrition update');
      toast.warn('Network error');
    }
  };

  // Handle the selection change for each meal
  const handleSelectChange = (mealType, selectedRecipes) => {
    if (mealType === 'lunch') {
      setLunchMeals(selectedRecipes); // Store selected values in state
    } else if (mealType === 'breakfast') {
      setBreakfastMeals(selectedRecipes);
    } else if (mealType === 'dinner') {
      setDinnerMeals(selectedRecipes);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: 'column' }}>
      <h2>Nutrition</h2>

      <div>
        <p>Lunch</p>
        <RecipeDropdown
          selectedRecieps={lunchMeals}
          onSelectChange={(e) => handleSelectChange('lunch', e.target.value)}
        />
      </div>

      <div>
        <p>Breakfast</p>
        <RecipeDropdown
          selectedRecieps={breakfastMeals}
          onSelectChange={(e) => handleSelectChange('breakfast', e.target.value)}
        />
      </div>

      <div>
        <p>Dinner</p>
        <RecipeDropdown
          selectedRecieps={dinnerMeals}
          onSelectChange={(e) => handleSelectChange('dinner', e.target.value)}
        />
      </div>

      <Button
        onClick={updateuserData}
        sx={{ marginTop: "10px" }}
        type="submit"
        variant="contained"
        color="primary"
      >
        Save
      </Button>
    </div>
  );
};

export default Nutrition;
