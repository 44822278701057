import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// const firebaseConfig = {
//   apiKey: "AIzaSyDMjvnbH3J74oA-jxc2PN8aL1NP5M8hin0",
//   authDomain: "prevealth.firebaseapp.com",
//   projectId: "prevealth",
//   storageBucket: "prevealth.appspot.com",
//   messagingSenderId: "837863211459",
//   appId: "1:837863211459:web:39af859ab4b91126ebbd76",
//   measurementId: "G-E2632YCBCY"
// };
const firebaseConfig = {
  apiKey: "AIzaSyDKYupFRwV6OAtalNRlpBrmrcHR1dpiVIM",
  authDomain: "authentication-47596.firebaseapp.com",
  databaseURL: "https://authentication-47596-default-rtdb.firebaseio.com",
  projectId: "authentication-47596",
  storageBucket: "authentication-47596.appspot.com",
  messagingSenderId: "467930929762",
  appId: "1:467930929762:web:e70a3c70d3c28b3873cf3b",
  measurementId: "G-36KQKGSMHP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
