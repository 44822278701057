import { getDoctros } from "../../functions/Doctors";
import { getAlluser } from "../../functions/users";
import { http } from "../../utils/http";

// Async action for adding an entry
export const addRecipessInReducer = (entry) => {
    return async (dispatch) => {
      try {
        const {data} = await http.get("/api/recipes");

        dispatch({
          type: 'GET_ALL_RECIPES',
          payload:  data?.data
        });
      } catch (error) {
        console.error('Error fetching users:', error);
        // You can dispatch an error action here if needed
        dispatch({
          type: 'ADD_ENTRY_ERROR',
          payload: error,
        });
      }
    };
  };



  export const getMydata = (entry) => {
    return async (dispatch) => {
      try {
        const {data}= await http.get('/api/users/me?populate=*');
        console.log('my',data)
        dispatch({
          type: 'ME_DATA',
          payload:  data
        });
      } catch (error) {
        console.error('Error fetching users:', error);
        // You can dispatch an error action here if needed
        dispatch({
          type: 'ADD_ENTRY_ERROR',
          payload: error,
        });
      }
    };
  };

  


  