import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Avatar,
  Box,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import { http } from "../../utils/http"; // Adjust the path as necessary
import { useNavigate, useParams } from "react-router-dom";
import { uploadimage } from "../../functions/upload";

const EditDoctor = () => {
  const { id } = useParams(); // Get the doctor ID from the URL
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    number: "",
    description: "",
    expertise: "",
    password: "",
    email: "",
    experience_year: "",
    selectedUsers: [],
    image: null,
  });

  const [users, setUsers] = useState([]);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  // Fetch users and doctor data
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await http.get("/api/users"); // Adjust the endpoint as necessary
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const fetchDoctorData = async () => {
      try {
        const response = await http.get(
          `/api/doctors-consultants/${id}?populate=image,users`
        );
        const doctor = response.data.data; // Adjust based on your API response structure

        setFormData({
          name: doctor.attributes.name || "",
          number: doctor.attributes.number || "",
          description: doctor.attributes.description || "",
          expertise: doctor.attributes.expertise || "",
          email: doctor.attributes.email || "",
          experience_year: doctor.attributes.experience_year || "",
          image: doctor.attributes.image?.data?.id || null, // Assuming 'image' is the media field
          selectedUsers:
            doctor.attributes.users?.data?.map((user) => user.id) || [],
        });

        // Set the image preview using the image URL
        if (doctor.attributes.image?.data?.attributes?.url) {
          setImagePreview(doctor.attributes.image.data.attributes.url);
        }
      } catch (error) {
        console.error("Error fetching doctor data:", error);
      }
    };

    fetchUsers();
    fetchDoctorData();
  }, [id]);
  
  console.log(formData);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);

      // Display image preview
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);

      try {
        // Upload the image using the uploadimage function
        const uploadedImage = await uploadimage(file);
        console.log(uploadedImage);

        setFormData((prevData) => ({
          ...prevData,
          image: uploadedImage.id, 
        }));
        console.log(formData);
        
      } catch (error) {
        console.error("Image upload failed:", error);
        toast.error("Failed to upload image.");
      }
    }
  };

  const handleSelectChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedUsers: e.target.value, // Array of selected user IDs
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: formData.name,
      number: formData.number,
      description: formData.description,
      expertise: formData.expertise,
      experience_year: Number(formData.experience_year),
      users: formData.selectedUsers, // Array of user IDs
      image: formData.image, // Image ID
    };

    try {
      // Update the doctor details in Strapi
      await http.put(`/api/doctors-consultants/${id}`, { data });

      toast.success("Doctor updated successfully");
      navigate("/Doctors");
    } catch (error) {
      console.error("Error updating doctor:", error);
      toast.error("Failed to update doctor.");
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={2}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" component="h1">
          Edit Doctor Details
        </Typography>
        {/* Placeholder for spacing */}
        <Box width="48px" />
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Doctor Name */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Doctor Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              margin="normal"
              size="small"
              required
            />
          </Grid>

          {/* Phone Number */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="number"
              value={formData.number}
              onChange={handleInputChange}
              margin="normal"
              size="small"
              required
            />
          </Grid>

          {/* Doctor Email */}
          {/* <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Doctor Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              margin="normal"
              size="small"
              required
              disabled // Typically, email should not be editable. Remove if needed.
            />
          </Grid> */}

          {/* Password */}
          {/* <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleInputChange}
              margin="normal"
              size="small"
              required
              disabled // Typically, password should not be editable here. Remove if needed.
            />
          </Grid> */}

          {/* Description */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              margin="normal"
              size="small"
              required
            />
          </Grid>

          {/* Expertise */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Expertise"
              name="expertise"
              value={formData.expertise}
              onChange={handleInputChange}
              margin="normal"
              size="small"
              required
            />
          </Grid>

          {/* Experience (Years) */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Experience (Years)"
              name="experience_year"
              type="number"
              value={formData.experience_year}
              onChange={handleInputChange}
              margin="normal"
              size="small"
            />
          </Grid>

          {/* Multiple Select Dropdown for Users */}
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal" size="small">
              <InputLabel>Select Users</InputLabel>
              <Select
                multiple
                value={formData.selectedUsers}
                onChange={handleSelectChange}
                label="Select Users"
                renderValue={(selected) =>
                  selected
                    .map((userId) => {
                      const user = users.find((u) => u.id === userId);
                      return user ? user.email : "";
                    })
                    .join(", ")
                }
              >
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    <Checkbox
                      checked={formData.selectedUsers.indexOf(user.id) > -1}
                    />
                    <ListItemText primary={user.email} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Upload Image */}
          <Grid item xs={12}>
            <InputLabel>Upload Image</InputLabel>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              style={{ marginTop: "8px" }}
            />
            {imagePreview && (
              <Box display="flex" justifyContent="center" marginY={2}>
                <Avatar
                  alt={formData.name}
                  src={imagePreview}
                  sx={{ width: 80, height: 80 }}
                />
              </Box>
            )}
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              sx={{ marginTop: "10px" }}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default EditDoctor;
