import React, { useEffect, useState } from 'react';
import { Button, TextField, Typography, Paper, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { loginWithEmail } from '../functions/users';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getMydata } from '../redux/action/useraction';

export default function Login() {



  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const login = async () => {
    try {
      const  data  = await loginWithEmail(email, password);
      // Handle successful login here
       dispatch(getMydata());
      if(data){
        navigate("/Home");
      }
    } catch (error) {
      console.error('Login failed:', error);
      // Handle error here
    }
  };

  return (
    <div style={styles.container}>
        <ToastContainer/>
      <Paper elevation={3} style={styles.paper}>
        <Typography variant="h4" style={styles.title}>
          Login
        </Typography>
        <form style={styles.form} onSubmit={(e) => { e.preventDefault(); login(); }}>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            style={styles.textField}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            fullWidth
            style={styles.textField}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            style={styles.button}
            type="submit"
          >
            Login
          </Button>
        </form>
      </Paper>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
  },
  paper: {
    padding: '40px',
    width: '400px',
    textAlign: 'center',
  },
  title: {
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    marginBottom: '20px',
  },
  button: {
    padding: '10px',
  },
};
