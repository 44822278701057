import { http, prodUrl } from "../utils/http"

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


export const getUser = async(id)=>{

try {
    const {data} = await http.get(`/api/users/${id}?populate=deep`);
    return data
} catch (error) {
    console.log(error);
}

}

export const updatecheckin = async(userid,checkInId,body)=>{

  try {
    // return checkInData;
      const {data} = await http.put(`/api/check-in-entries/${checkInId}`,{data:{user:userid,...body}});
      // const userupdate = await http.put(`/api/users/${userid}`,{data:{
      //   check_in_entry:data?.data?.id
      // }})
      return data
  } catch (error) {
      console.log(error);
  }
  
  }

export const getAlluser = async()=>{
  try {
      const {data} = await http.get("/api/users?populate=img&filters[email][$nei]=admin@gmail.com");
      console.log(data);
      return data
  } catch (error) {
      console.log(error);
      return 0 
  }
  
  }
  
  



export const loginWithEmail = async (email, password) => {
  if (!password || !email) {
    toast.error('Please Fill Both Fields');
    return;
  }

  try {
    const { data } = await axios.post(`${prodUrl}api/auth/local`, {
      identifier: email,
      password: password
    });

    if (!data?.user) {
      toast.error('Wrong Credentials');
      return;
    }
    console.log(data?.jwt)
    localStorage.setItem('userToken', JSON.stringify(data?.jwt));
    localStorage.setItem('userId', JSON.stringify(data?.user?.id));

    toast.success('Login Successful!');
    return data;

  } catch (error) {
    toast.error('An error occurred during login');
    console.error(error);
  }
};

