import React, { useEffect, useState } from "react";
import { http } from "../utils/http";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { getManagePlans } from "../functions/plans";

function Notifications() {
  const [data, setData] = useState();

  const notifications = async () => {
    try {
      const { data } = await http.get("/api/crm-notifications?populate=*");
      setData(data?.data);
      console.log(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    notifications();
  }, []);

  const columns = [
    {
      accessorKey: "attributes.users_permissions_user.data.attributes.username",
      header: "User Name",
    },
    {
      accessorKey: "attributes.users_permissions_user.data.attributes.email",
      header: "User Email",
    },
    {
      accessorKey: "attributes.text",
      header: "Notification Text",
    },
    {
      accessorKey: "attributes.createdAt",
      header: "Created At ",
    },
  ];

  return (
    <MaterialReactTable
      columns={columns}
      data={data ? data : []}
      initialState={{ pageSize: 5 }} // Optional: set the initial page size
      enablePagination
      enableSorting
    />
  );
}

export default Notifications;
