import React, { useState, useEffect } from "react";
import { List, ListItem, ListItemText, Divider, Collapse } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function Sidebar() {
  const mydata = useSelector((state) => state?.user?.me);
  const location = useLocation();
  const [openForms, setOpenForms] = useState(false);

  // Keep track of the active path and handle the Form expandable menu
  useEffect(() => {
    if (location.pathname.startsWith("/Form")) {
      setOpenForms(true); // Automatically open the "Form" submenu when in a Form route
    } else {
      setOpenForms(false);
    }
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.removeItem("userToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("user");
    toast.success("Logout successfully");
  };

  const sidebarOptions =
    mydata?.role?.name !== "Authenticated"
      ? [
          { text: "Dashboard", path: "/" },
          { text: "Forms", path: "/Form", isExpandable: true },

          { text: "Messages", path: "/Inbox" },
          { text: "Users", path: "/Users" },
          { text: "Manage plans", path: "/ManagePlans" },
          { text: "Health Tasks", path: "/Healthtasks" },
          { text: "Recipes", path: "/Recipes" },
          { text: "Workouts", path: "/Workouts" },
          { text: "Help", path: "/help" },
        ]
      : [
          { text: "Dashboard", path: "/" },
          { text: "Forms", path: "/Form", isExpandable: true },

          { text: "Messages", path: "/Inbox" },
          { text: "Users", path: "/Users" },

          { text: "Manage plans", path: "/ManagePlans" },
          { text: "Doctors", path: "/Doctors" },
          { text: "Health Tasks", path: "/Healthtasks" },

          { text: "Recipes", path: "/Recipes" },
          { text: "Workouts", path: "/Workouts" },
          { text: "Help", path: "/help" },
          { text: "Log out", onClick: handleLogout },
        ];

  const handleFormsClick = () => {
    setOpenForms(!openForms);
  };

  return (
    <div
      style={{
        width: "200px",
        padding: "10px",
        borderRight: "1px solid #ddd",
      }}
    >
      <List>
        {sidebarOptions.map((item, index) => {
          const isActive =
            item.path === "/"
              ? location.pathname === item.path
              : location.pathname.startsWith(item.path);

          return (
            <div key={index}>
              {item.isExpandable ? (
                <>
                  <ListItem
                    button
                    onClick={handleFormsClick}
                    style={{
                      backgroundColor: location.pathname.startsWith("/Form")
                        ? "black"
                        : "transparent",
                      color: location.pathname.startsWith("/Form")
                        ? "white"
                        : "black",
                      borderRadius: "4px",
                      marginBottom: "5px",
                      width: "180px",
                      transition: "background-color 0.3s ease",
                      cursor: "pointer",
                    }}
                  >
                    <ListItemText primary={item.text} />
                  </ListItem>
                  <Collapse in={openForms} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        component={Link}
                        to="/Form/FormBuilder"
                        style={{
                          paddingLeft: "30px",
                          backgroundColor:
                            location.pathname === "/Form/FormBuilder"
                              ? "black"
                              : "transparent",
                          color:
                            location.pathname === "/Form/FormBuilder"
                              ? "white"
                              : "black",
                          transition: "background-color 0.3s ease",
                        }}
                      >
                        <ListItemText primary="Create Forms" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        to="/Form/Template"
                        style={{
                          paddingLeft: "30px",
                          backgroundColor:
                            location.pathname === "/Form/Template"
                              ? "black"
                              : "transparent",
                          color:
                            location.pathname === "/Form/Template"
                              ? "white"
                              : "black",
                          transition: "background-color 0.3s ease",
                        }}
                      >
                        <ListItemText primary="Template" />
                      </ListItem>
                      <ListItem
                        button
                        component={Link}
                        to="/Form/Submissions"
                        style={{
                          paddingLeft: "30px",
                          backgroundColor:
                            location.pathname === "/Form/Submissions"
                              ? "black"
                              : "transparent",
                          color:
                            location.pathname === "/Form/Submissions"
                              ? "white"
                              : "black",
                          transition: "background-color 0.3s ease",
                        }}
                      >
                        <ListItemText primary="Submissions" />
                      </ListItem>
                    </List>
                  </Collapse>
                </>
              ) : (
                <>
                  <ListItem
                    button
                    component={item.path ? Link : "div"}
                    to={item.path || undefined}
                    onClick={item.onClick ? item.onClick : undefined}
                    style={{
                      backgroundColor: isActive ? "black" : "transparent",
                      color: isActive ? "white" : "black",
                      borderRadius: "4px",
                      marginBottom: "5px",
                      width: "180px",
                      transition: "background-color 0.3s ease",
                    }}
                    onMouseEnter={(e) => {
                      if (!isActive)
                        e.currentTarget.style.backgroundColor = "#f0f0f0";
                    }}
                    onMouseLeave={(e) => {
                      if (!isActive)
                        e.currentTarget.style.backgroundColor = "transparent";
                    }}
                  >
                    <ListItemText primary={item.text} />
                  </ListItem>
                  {index < sidebarOptions.length - 1 && <Divider />}
                </>
              )}
            </div>
          );
        })}
      </List>
    </div>
  );
}
