import React, { useState, useEffect } from "react";
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import { db } from "../../firebase.config"; // Import Firebase Firestore
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment"; // Import moment for date formatting

const Inbox = () => {
  const [chats, setChats] = useState([]);
  const mydata = useSelector((state) => state?.user?.me);

  useEffect(() => {
    if (!mydata?.email) return; // Add check to ensure mydata is loaded

    const q = query(
      collection(db, "chats"),
      where("participants", "array-contains", mydata.email),
    );
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      let chatList = [];
      snapshot.forEach((doc) => {
        chatList.push({ id: doc.id, ...doc.data() });
      });
      setChats(chatList);
    });

    return () => unsubscribe();
  }, [mydata?.email]); // Add dependency on mydata email

  return (
    <div>
      <h2>Inbox</h2>
      {chats.map((chat) => (
        <Link key={chat.id} to={`/chat/${chat.id}`}>
          <div style={styles.chatItem}>
            <h3>
              {chat.participants?.filter((user) => user !== mydata?.email) // Exclude logged-in user's email
                .join(", ")}
            </h3>
          
            <p>
              <strong>Time: </strong>
              {chat.lastMessageTimestamp
                ? moment(chat.lastMessageTimestamp.toDate()).format("hh:mm A, MMM DD YYYY")
                : "No time available"}
            </p>
          </div>
        </Link>
      ))}
    </div>
  );
};

const styles = {
  chatItem: {
    padding: "10px",
    borderBottom: "1px solid #ccc",
    cursor: "pointer",
  },
};

export default Inbox;
