import React from "react";
import { Card, CardContent, Typography, CardMedia } from "@mui/material";
import {
  Person,
  FitnessCenter,
  InsertPhoto,
  CalendarToday,
} from "@mui/icons-material";

function Overview({ user }) {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6">{user.username}</Typography>
        <Typography color="text.secondary">{user.email}</Typography>
        <Typography variant="body1">
          <Person style={{ verticalAlign: "middle", marginRight: 8 }} />
          <strong>Gender:</strong> {user.gender || "N/A"}
        </Typography>
        <Typography variant="body1">
          <FitnessCenter style={{ verticalAlign: "middle", marginRight: 8 }} />
          <strong>Weight:</strong> {user.weight || "N/A"}
        </Typography>
        <Typography variant="body1">
          <InsertPhoto style={{ verticalAlign: "middle", marginRight: 8 }} />
          <strong>Height:</strong> {user.height || "N/A"}
        </Typography>
        <Typography variant="body1">
          <CalendarToday style={{ verticalAlign: "middle", marginRight: 8 }} />
          <strong>Date of Birth:</strong> {user.DOB || "N/A"}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default Overview;
