import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Button,
  IconButton,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { http } from "../../utils/http"; // Assuming you have this for HTTP requests
import { toast } from "react-toastify";

import { getDoctros } from "../../functions/Doctors";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Doctors() {
  const [data, setData] = useState([]);
  const users = useSelector((state) => state);
  console.log(users);

  const getdoctros = async () => {
    try {
      const { data } = await getDoctros();
      console.log(data);
      setData(data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getdoctros();
  }, []);

  const navigate = useNavigate();

  const columns = [
    {
      accessorKey: "attributes.name",
      header: "Doctor Name",
    },
    {
      accessorKey: "attributes.img",
      header: "Image",
      Cell: ({ cell }) =>
        cell.row.original.attributes?.image?.data?.[0].attributes?.url ? (
          <img
            style={{
              height: 40,
              width: 40,
              borderRadius: 40,
              backgroundColor: "black",
            }}
            src={cell.row.original.attributes?.image?.data?.[0].attributes?.url}
            alt="Doctor"
          />
        ) : (
          <Avatar
            alt={cell.row.original.attributes.dr_name}
            src={cell.getValue()}
          />
        ),
    },
    {
      accessorKey: "attributes.expertise",
      header: "Expertise",
    },
    {
      accessorKey: "attributes.experience_year",
      header: "Experience",
    },
    {
      accessorKey: "attributes.description",
      header: "Description",
      Cell: ({ cell }) => {
        const description = cell.getValue();
        return description && description.length > 20
          ? `${description.substring(0, 20)}...`
          : description;
      },
    },
    {
      accessorKey: "actions",
      header: "",
      enableSorting: false,
      enableColumnActions: false,
      Cell: ({ row }) => (
        <div style={{ display: "flex", gap: "2px" }}>
          <IconButton onClick={() => handleEdit(row.original.id)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleRowClick = (row) => {
    const userId = row.original.id; // Assuming each user has an `id`
    navigate(`/Doctor/${userId}`); // Navigate to the user details page
  };

  const handleEdit = (id) => {
    navigate(`/EditDoctor/${id}`); // Navigate to the EditDoctor page with the doctor's ID
  };

  const handleDelete = async (id) => {
    try {
      await http.delete(`/api/doctors-consultants/${id}`);
      toast.success("Doctor deleted successfully");
      getdoctros();
    } catch (error) {
      console.error("Error deleting doctor:", error);
      toast.error("Error deleting doctor");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button
        sx={{ backgroundColor: "black", marginBottom: "10px" }}
        onClick={() => navigate("/CreateDoctor")}
        variant="contained"
        component="label"
      >
        Create Doctor
      </Button>
      <MaterialReactTable
        columns={columns}
        data={data ? data : []}
        initialState={{ pageSize: 5 }} // Optional: set the initial page size
        enablePagination
        enableSorting
        muiTableBodyRowProps={({ row }) => ({
          // onClick: () => handleRowClick(row),
          style: { cursor: "pointer" },
        })}
      />
    </div>
  );
}

export default Doctors;
