


import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Button,
  IconButton,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { http } from "../../utils/http"; // Assuming you have this for HTTP requests
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllTemplates } from "../../redux/action/forms";
import moment from "moment";

function Templates() {
//   const [data, setData] = useState([]);
  const users = useSelector((state) => state);
  console.log(users);

  const data = useSelector((state)=>state?.user?.templates);
  const dispatch = useDispatch()
  useEffect(()=>{
  dispatch(getAllTemplates());
  },[])




  const navigate = useNavigate();

  const columns = [
    {
        accessorKey: "attributes.createdAt",
        header: "Creation Date",
        cell: (info) => moment(info.getValue()).format("YYYY-MM-DD"), // Format the date
      },
   
  ];

  const handleRowClick = (row) => {
    const id = row.original.id; // Assuming each user has an `id`
    navigate(`/Form/FormBuilder/${id}`); // Navigate to the user details page
  };

  const handleEdit = (id) => {
    navigate(`/EditDoctor/${id}`); // Navigate to the EditDoctor page with the doctor's ID
  };

  const handleDelete = async (id) => {
    try {
      await http.delete(`/api/doctors-consultants/${id}`);
      toast.success("Doctor deleted successfully");
    //   getdoctros();
    } catch (error) {
      console.error("Error deleting doctor:", error);
      toast.error("Error deleting doctor");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>

      <MaterialReactTable
        columns={columns}
        data={data ? data : []}
        initialState={{ pageSize: 5 }} // Optional: set the initial page size
        enablePagination
        enableSorting
        muiTableBodyRowProps={({ row }) => ({
          onClick: () => handleRowClick(row),
          style: { cursor: "pointer" },
        })}
      />
    </div>
  );
}

export default Templates;
