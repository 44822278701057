import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { useSelector } from 'react-redux';

const UserDropdown = ({ selectedUsers, onSelectChange }) => {
    const users  = useSelector((state)=>state.user?.userArray);;
     
  return (
    <FormControl fullWidth margin="normal" size="small">
      <InputLabel>Select Users</InputLabel>
      <Select
        multiple
        value={selectedUsers}
        onChange={onSelectChange}
        label="Select Users"
        renderValue={(selected) =>
          selected.map((userId) => {
            const user = users.find((u) => u.id === userId);
            return user ? user.email : '';
          }).join(', ')
        }
      >
        {users.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            <Checkbox checked={selectedUsers.includes(user.id)} />
            <ListItemText primary={user.email} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default UserDropdown;
