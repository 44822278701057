import React, { useState, useEffect } from "react";
import { collection, addDoc, onSnapshot, query, orderBy } from "firebase/firestore";
import { db } from "../../firebase.config";
import { useParams } from "react-router-dom";
import moment from "moment"; // Import moment.js
import { useSelector } from "react-redux";

const ChatDetail = () => {
  const { chatId } = useParams(); // Get chat ID from URL params
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const mydata = useSelector((state)=>state?.user?.me);

  useEffect(() => {
    const q = query(
      collection(db, "chats", chatId, "messages"),
      orderBy("timestamp", "asc")
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      let msgs = [];
      snapshot.forEach((doc) => {
        msgs.push(doc.data());
      });
      setMessages(msgs);
    });

    return () => unsubscribe();
  }, [chatId]);

  const sendMessage = async () => {
    if (newMessage.trim() === "") return;

    await addDoc(collection(db, "chats", chatId, "messages"), {
      text: newMessage,
      sender: mydata?.email,
      timestamp: new Date(),
    });

    setNewMessage(""); // Clear input after sending
  };

  return (
    <div>
      <div style={styles.chatContainer}>
        {messages.map((msg, index) => (
          <div
            key={index}
            style={{
              ...styles.message,
              ...(msg.sender === mydata?.email ? styles.sent : styles.received),
            }}
          >
            <p>{msg.text}</p>
            <small style={styles.timestamp}>
              {moment(msg.timestamp?.toDate()).format("hh:mm A")} {/* Format timestamp */}
            </small>
          </div>
        ))}
      </div>
      <div style={styles.inputContainer}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message..."
          style={styles.input}
        />
        <button onClick={sendMessage} style={styles.sendButton}>
          Send
        </button>
      </div>
    </div>
  );
};

const styles = {
  chatContainer: {
    height: "370px",
    overflowY: "scroll",
    padding: "10px",
    borderBottom: "1px solid #ccc",
  },
  message: {
    padding: "10px",
    margin: "10px 0",
    borderRadius: "10px",
    maxWidth: "70%",
  },
  sent: {
    backgroundColor: "#dcf8c6",
    alignSelf: "flex-end",
    marginLeft: "auto",
  },
  received: {
    backgroundColor: "white",
    alignSelf: "flex-start",
  },
  inputContainer: {
    display: "flex",
    marginTop: "10px",
  },
  input: {
    flex: 1,
    padding: "10px",
    fontSize: "16px",
  },
  sendButton: {
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    cursor: "pointer",
  },
  timestamp: {
    fontSize: "12px",
    color: "#888",
    marginTop: "5px",
    textAlign: "right",
  },
};

export default ChatDetail;
