import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Container,
  Avatar,
  Box,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import { http } from "../../utils/http"; // Assuming you have this for HTTP requests
import { useNavigate } from "react-router-dom";
import { uploadimage } from "../../functions/upload";

const CreateDoctor = () => {
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    description: "",
    expertise: "",
    password: "",
    email: "",
    experience_year: "",
    image: null,
    selectedUsers: [],
  });

  const [users, setUsers] = useState([]);
  const [imagePreview, setImagePreview] = useState(null); // To hold the image preview URL
  const [imageFile, setImageFile] = useState(null);
  // Fetch users from an API or load them dynamically

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await http.get("/api/users"); // Assuming this endpoint returns a list of users
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Set the image preview URL
      };
      reader.readAsDataURL(file); // Convert the file to a base64 string
    }
  };

  const handleSelectChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedUsers: e.target.value, // Array of selected users
    }));
  };

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    let imageid = null;
    
    if (imageFile) {
      imageid = await uploadimage(imageFile);
      setFormData((prevData) => ({
        ...prevData,
        image: imageid.id,
      }));
    }
        
    const data = {
      name: formData.name,
      number: formData.number,
      description: formData.description,
      expertise: formData.expertise,
      experience_year: Number(formData.experience_year),
      users: formData.selectedUsers,
      image: imageid.id,
    };

    console.log(data);

    try {
      const response = await http.post("api/doctors-consultants", {
        data: data,
      });

      const createuser = await http.post("/api/auth/local/register", {
        password: formData.password,
        email: formData.email,
        username: formData.email,
      });

      console.log(createuser);

      const d = await http.put(`/api/users/${createuser?.data?.user?.id}`, {
        role: 3,
        // me_as_doctor: formData.email
      });
      const doc = await http.put(
        `/api/doctors-consultants/${response?.data?.data?.id}`,
        {
          data: { my_data: createuser?.data?.user?.id },
        }
      );
      toast.success("Successfully created");
      navigate("/Doctors");
      console.log(d.data);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="normal"
        gap={"200px"}
      >
        <IconButton
          onClick={() => navigate(-1)}
          style={{ marginLeft: "-240px" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h4" component="h1">
          Add Doctor Details
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Doctor Name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              margin="normal"
              size="small"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="number"
              value={formData.number}
              onChange={handleInputChange}
              margin="normal"
              size="small"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Doctor Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              margin="normal"
              size="small"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Passwrod"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              margin="normal"
              size="small"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              margin="normal"
              size="small"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Expertise"
              name="expertise"
              value={formData.expertise}
              onChange={handleInputChange}
              margin="normal"
              size="small"
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Experience (Years)"
              name="experience_year"
              type="number"
              value={formData.experience_year}
              onChange={handleInputChange}
              margin="normal"
              size="small"
            />
          </Grid>

          {/* Multiple select dropdown for users */}
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal" size="small">
              <InputLabel>Select Users</InputLabel>
              <Select
                multiple
                value={formData.selectedUsers}
                onChange={handleSelectChange}
                label="Select Users"
                renderValue={(selected) =>
                  selected
                    .map((userId) => {
                      const user = users.find((u) => u.id === userId);
                      return user ? user.email : "";
                    })
                    .join(", ")
                }
              >
                {users.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    <Checkbox
                      checked={formData.selectedUsers.indexOf(user.id) > -1}
                    />
                    <ListItemText primary={user.email} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* <Box display="flex" justifyContent="center" marginY={2}>
          <Avatar
            alt={formData.name}
            src={formData.imageUrl}
            sx={{ width: 80, height: 80 }}
          />
        </Box> */}
        <Grid item xs={12}>
          <InputLabel>Upload Image</InputLabel>
          <input type="file" accept="image/*" required onChange={handleImageUpload} />
          {imagePreview && (
            <Box display="flex" justifyContent="center" marginY={2}>
              <Avatar
                alt={formData.name}
                src={imagePreview}
                sx={{ width: 80, height: 80 }}
              />
            </Box>
          )}
        </Grid>
        <Button
          sx={{ marginTop: "10px" }}
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
        >
          Submit
        </Button>
      </form>
    </Container>
  );
};

export default CreateDoctor;
