import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { http } from "../../utils/http";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EditRecipe = () => {
  const [formData, setFormData] = useState({
    name: "",
    Ingredients_with_it_smeasure: [],
    method_of_preparation: [],
    application: [],
    nutrients_in_rich: [],
    benefits: [],
    nutrient_table: [],
    img: null,
    video: null,
  });

  const navigate = useNavigate();
  const { id } = useParams(); // Get the recipe ID from the URL

  // Fetch the existing recipe data
  const getRecipe = async () => {
    try {
      const response = await http.get(`/api/recipes/${id}?populate=deep`);
      const recipeData = response.data.data; // Adjust based on your API response structure
      setFormData({
        name: recipeData.attributes.name,
        Ingredients_with_it_smeasure:
          recipeData.attributes.Ingredients_with_it_smeasure,
        method_of_preparation: recipeData.attributes.method_of_preparation,
        application: recipeData.attributes.application,
        nutrients_in_rich: recipeData.attributes.nutrients_in_rich,
        benefits: recipeData.attributes.benefits,
        nutrient_table: recipeData.attributes.nutrient_table,
        img: recipeData.attributes.img,
        video: recipeData.attributes.video,
      });
    } catch (error) {
      console.error("Error fetching recipe data:", error);
      toast.error("Error fetching recipe data");
    }
  };

  useEffect(() => {
    getRecipe();
  }, [id]);

  // Handle array input change (e.g., Ingredients, benefits, etc.)
  const handleArrayChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: [...formData[name], { point: value }],
    });
  };

  // Handle removal of array items
  const handleArrayRemove = (name, index) => {
    setFormData({
      ...formData,
      [name]: formData[name].filter((_, i) => i !== index),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      name: formData.name,
      Ingredients_with_it_smeasure: formData.Ingredients_with_it_smeasure,
      method_of_preparation: formData.method_of_preparation,
      application: formData.application,
      nutrients_in_rich: formData.nutrients_in_rich,
      benefits: formData.benefits,
      nutrient_table: formData.nutrient_table,
    };

    try {
      await http.put(`/api/recipes/${id}`, { data });
      toast.success("Recipe updated successfully");
      navigate("/Recipes");
    } catch (error) {
      console.error("Error updating recipe:", error);
      toast.error("Error updating recipe");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {/* Name */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
          />
        </Grid>

        {/* Ingredients with Measure */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Ingredients with Measure"
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value.trim() !== "") {
                handleArrayChange(
                  "Ingredients_with_it_smeasure",
                  e.target.value
                );
                e.preventDefault();
              }
            }}
            placeholder="Press Enter to add"
          />
          <List>
            {formData.Ingredients_with_it_smeasure.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item.point} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() =>
                      handleArrayRemove("Ingredients_with_it_smeasure", index)
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Method of Preparation */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Method of Preparation"
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value.trim() !== "") {
                handleArrayChange("method_of_preparation", e.target.value);
                e.preventDefault();
              }
            }}
            placeholder="Press Enter to add"
          />
          <List>
            {formData.method_of_preparation.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item.point} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() =>
                      handleArrayRemove("method_of_preparation", index)
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Application */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Application"
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value.trim() !== "") {
                handleArrayChange("application", e.target.value);
                e.preventDefault();
              }
            }}
            placeholder="Press Enter to add"
          />
          <List>
            {formData.application.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item.point} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => handleArrayRemove("application", index)}
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Nutrients in Rich */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nutrients in Rich"
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value.trim() !== "") {
                handleArrayChange("nutrients_in_rich", e.target.value);
                e.preventDefault();
              }
            }}
            placeholder="Press Enter to add"
          />
          <List>
            {formData.nutrients_in_rich.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item.point} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() =>
                      handleArrayRemove("nutrients_in_rich", index)
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Benefits */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Benefits"
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.target.value.trim() !== "") {
                handleArrayChange("benefits", e.target.value);
                e.preventDefault();
              }
            }}
            placeholder="Press Enter to add"
          />
          <List>
            {formData.benefits.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item.point} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => handleArrayRemove("benefits", index)}
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Nutrient Table */}
        <Grid item xs={12}>
          {/* Add your nutrient table fields here */}
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Update Recipe
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditRecipe;
