import React, { useState } from "react";
import {
  TextField,
  Button,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
  Typography,
  Avatar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { http } from "../../utils/http";
import { useSelector } from "react-redux";
import UserDropdown from "../../Components/UserDropdown";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadimage } from "../../functions/upload";

const CreateRecipeform = () => {
  const [formData, setFormData] = useState({
    name: "",
    Ingredients_with_it_smeasure: [],
    method_of_preparation: [],
    application: [],
    nutrients_in_rich: [],
    benefits: [],
    nutrient_table: [],
    img: null,
    video: null,
    selectedUsers: [],
  });

  const [inputFields, setInputFields] = useState({
    ingredient: "",
    preparationStep: "",
    applicationItem: "",
    nutrient: "",
    benefit: "",
    nutrientName: "",
    nutrientValue: "",
  });

  const users = useSelector((state) => state.user);
  
  const [imagePreview, setImagePreview] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const navigate = useNavigate();

  // Handle array input change (e.g., Ingredients, benefits, etc.)
  const handleArrayChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: [...formData[name], { point: value }],
    });
    setInputFields({ ...inputFields, [name]: "" });
  };

  // Handle removal of array items
  const handleArrayRemove = (name, index) => {
    setFormData({
      ...formData,
      [name]: formData[name].filter((_, i) => i !== index),
    });
  };

  // Handle nutrient table entry change
  const handleNutrientTableChange = (name, value) => {
    setFormData({
      ...formData,
      nutrient_table: [...formData.nutrient_table, { name, value }],
    });
    setInputFields({ nutrientName: "", nutrientValue: "" });
  };

  const handleFileChange = async (e) => {
    const { name, files } = e.target;
    const file = files[0];
    if (file) {
      try {
        // Upload the file to Strapi
        const uploadedMedia = await uploadimage(file);
        console.log(`Uploaded ${name}:`, uploadedMedia);

        // Update formData with media ID
        setFormData((prevData) => ({
          ...prevData,
          [name]: uploadedMedia.id,
        }));

        // Set preview
        if (name === "img") {
          setImagePreview(uploadedMedia.url);
        } else if (name === "video") {
          setVideoPreview(uploadedMedia.url);
        }

        toast.success(
          `${name === "img" ? "Image" : "Video"} uploaded successfully`
        );
      } catch (error) {
        console.error(`Error uploading ${name}:`, error);
        toast.error(`Failed to upload ${name === "img" ? "image" : "video"}`);
      }
    }
  };

  const handleSelectChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      selectedUsers: e.target.value, // Array of selected users
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const ingredients = formData.Ingredients_with_it_smeasure.map(
      (item, index) => ({
        id: item.id || null, // Use the existing id if available, otherwise set it to null
        point: item.point,
        __temp_key__: index,
      })
    );

    const data = {
      name: formData.name,
      Ingredients_with_it_smeasure: ingredients,
      method_of_preparation: formData.method_of_preparation.map(
        (item, index) => ({
          id: item.id || null,
          point: item.point,
          __temp_key__: index,
        })
      ),
      application: formData.application.map((item, index) => ({
        id: item.id || null,
        point: item.point,
        __temp_key__: index,
      })),
      nutrients_in_rich: formData.nutrients_in_rich.map((item, index) => ({
        id: item.id || null,
        point: item.point,
        __temp_key__: index,
      })),
      benefits: formData.benefits.map((item, index) => ({
        id: item.id || null,
        point: item.point,
        __temp_key__: index,
      })),
      nutrient_table: formData.nutrient_table,
      users: formData.selectedUsers,
      img: formData.img,
      video: formData.video,
      create_by: localStorage.getItem("userId"),
    };

    try {
      const response = await http.post("/api/recipes", { data: data });
      toast.success("Successfully created");
      navigate("/Recipes");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error creating recipe");
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {/* Name */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
          />
        </Grid>

        {/* Ingredients with Measure */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Ingredients with Measure"
            value={inputFields.ingredient}
            onChange={(e) =>
              setInputFields({ ...inputFields, ingredient: e.target.value })
            }
            onKeyDown={(e) => {
              if (e.key === "Enter" && inputFields.ingredient.trim() !== "") {
                handleArrayChange(
                  "Ingredients_with_it_smeasure",
                  inputFields.ingredient
                );
                e.preventDefault();
              }
            }}
            placeholder="Press Enter to add"
          />

          <List>
            {formData.Ingredients_with_it_smeasure.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item.point} />{" "}
                {/* Accessing the point property */}
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() =>
                      handleArrayRemove("Ingredients_with_it_smeasure", index)
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Method of Preparation */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Method of Preparation"
            value={inputFields.preparationStep}
            onChange={(e) =>
              setInputFields({
                ...inputFields,
                preparationStep: e.target.value,
              })
            }
            onKeyDown={(e) => {
              if (
                e.key === "Enter" &&
                inputFields.preparationStep.trim() !== ""
              ) {
                handleArrayChange(
                  "method_of_preparation",
                  inputFields.preparationStep
                );
                e.preventDefault();
              }
            }}
            placeholder="Press Enter to add"
          />
          <List>
            {formData.method_of_preparation.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item.point} />{" "}
                {/* Accessing the point property */}
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() =>
                      handleArrayRemove("method_of_preparation", index)
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Application */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Application"
            value={inputFields.applicationItem}
            onChange={(e) =>
              setInputFields({
                ...inputFields,
                applicationItem: e.target.value,
              })
            }
            onKeyDown={(e) => {
              if (
                e.key === "Enter" &&
                inputFields.applicationItem.trim() !== ""
              ) {
                handleArrayChange("application", inputFields.applicationItem);
                e.preventDefault();
              }
            }}
            placeholder="Press Enter to add"
          />
          <List>
            {formData.application.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item.point} />{" "}
                {/* Accessing the point property */}
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => handleArrayRemove("application", index)}
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Nutrients in Rich */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nutrients in Rich"
            value={inputFields.nutrient}
            onChange={(e) =>
              setInputFields({ ...inputFields, nutrient: e.target.value })
            }
            onKeyDown={(e) => {
              if (e.key === "Enter" && inputFields.nutrient.trim() !== "") {
                handleArrayChange("nutrients_in_rich", inputFields.nutrient);
                e.preventDefault();
              }
            }}
            placeholder="Press Enter to add"
          />
          <List>
            {formData.nutrients_in_rich.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item.point} />{" "}
                {/* Accessing the point property */}
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() =>
                      handleArrayRemove("nutrients_in_rich", index)
                    }
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Benefits */}
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Benefits"
            value={inputFields.benefit}
            onChange={(e) =>
              setInputFields({ ...inputFields, benefit: e.target.value })
            }
            onKeyDown={(e) => {
              if (e.key === "Enter" && inputFields.benefit.trim() !== "") {
                handleArrayChange("benefits", inputFields.benefit);
                e.preventDefault();
              }
            }}
            placeholder="Press Enter to add"
          />
          <List>
            {formData.benefits.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={item.point} />{" "}
                {/* Accessing the point property */}
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => handleArrayRemove("benefits", index)}
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Nutrient Table */}
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Nutrient Name"
            value={inputFields.nutrientName}
            onChange={(e) =>
              setInputFields({ ...inputFields, nutrientName: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label="Nutrient Value"
            value={inputFields.nutrientValue}
            onChange={(e) =>
              setInputFields({ ...inputFields, nutrientValue: e.target.value })
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => {
              if (inputFields.nutrientName && inputFields.nutrientValue) {
                handleNutrientTableChange(
                  inputFields.nutrientName,
                  inputFields.nutrientValue
                );
              }
            }}
          >
            Add Nutrient
          </Button>
          <List>
            {formData.nutrient_table.map((item, index) => (
              <ListItem key={index}>
                <ListItemText primary={`${item.name}: ${item.value}`} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => handleArrayRemove("nutrient_table", index)}
                  >
                    <CloseIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Image and Video Upload */}
        <Grid item xs={12}>
          <Button variant="contained" component="label">
            Upload Image
            <input
              type="file"
              hidden
              name="img"
              accept="image/*"
              onChange={handleFileChange}
            />
          </Button>
          {formData.img && imagePreview && (
            <Box mt={2}>
              <Typography variant="subtitle1">Image Preview:</Typography>
              <Avatar
                src={imagePreview}
                alt="Recipe Image"
                variant="rounded"
                sx={{ width: 200, height: 200 }}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" component="label">
            Upload Video
            <input
              type="file"
              hidden
              name="video"
              accept="video/*"
              onChange={handleFileChange}
            />
          </Button>
          {formData.video && videoPreview && (
            <Box mt={2}>
              <Typography variant="subtitle1">Video Preview:</Typography>
              <video width="320" height="240" controls>
                <source src={videoPreview} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Box>
          )}
        </Grid>

        <Grid item xs={12}>
          <UserDropdown
            users={users}
            selectedUsers={formData.selectedUsers}
            onSelectChange={handleSelectChange}
          />
        </Grid>
        {/* Submit Button */}
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateRecipeform;
