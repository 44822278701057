import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Button,
  IconButton,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// import { getDoctros } from '../../functions/Workouts';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { http } from "../../utils/http";
import { toast } from "react-toastify";

function Workouts() {
  const [data, setData] = useState();
  const users = useSelector((state) => state);
  const navigate = useNavigate();

  const getWorkouts = async () => {
    try {
      const fetchedData = await http.get("/api/workouts?populate=*");

      if (fetchedData && fetchedData.data) {
        setData(fetchedData.data.data);
      } else {
        toast.warn("No workouts found.");
      }
    } catch (error) {
      toast.error("Error fetching workouts.");
    }
  };

  useEffect(() => {
    getWorkouts();
  }, []);

  const columns = [
    {
      accessorKey: "attributes.name",
      header: "Exercise Name",
    },
    {
      accessorKey: "attributes.img",
      header: "Image",
      Cell: ({ cell }) =>
        cell.row.original.attributes?.img?.data?.[0].attributes?.url ? (
          <img
            style={{
              height: 40,
              width: 40,
              borderRadius: 40,
              backgroundColor: "black",
            }}
            src={cell.row.original.attributes?.img?.data?.[0].attributes?.url}
            alt="Doctor"
          />
        ) : (
          <Avatar
            alt={cell.row.original.attributes.dr_name}
            src={cell.getValue()}
          />
        ),
    },
    {
      accessorKey: "attributes.description",
      header: "Description",
    },
    {
      accessorKey: "attributes.category",
      header: "Category",
    },
    {
      accessorKey: "actions",
      header: "",
      enableSorting: false,
      enableColumnActions: false,
      Cell: ({ row }) => (
        <div style={{ display: "flex", gap: "2px" }}>
          <IconButton onClick={() => handleEdit(row.original.id)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(row.original.id)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleEdit = (id) => {
    navigate(`/EditWorkout/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await http.delete(`/api/workouts/${id}`);
      toast.success("Workout deleted successfully");
      getWorkouts();
    } catch (error) {
      console.error("Error deleting doctor:", error);
      toast.error("Error deleting doctor");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Button
        sx={{ backgroundColor: "black", marginBottom: "10px" }}
        onClick={() => navigate("/CreateWorkout")}
        variant="contained"
        component="label"
      >
        Create Workouts
      </Button>
      <MaterialReactTable
        columns={columns}
        data={data ? data : []}
        initialState={{ pageSize: 5 }} // Optional: set the initial page size
        enablePagination
        enableSorting
      />
    </div>
  );
}

export default Workouts;
